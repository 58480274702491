import { useEffect, useState } from "react";

export const usePageBottom = () => {
  const [reachedBottom, setReachedBottom] = useState(false);
  useEffect(() => {
    if (reachedBottom) {
      return;
    }
    const handleScroll = () => {
      const offsetHeight = document.documentElement.offsetHeight;
      const innerHeight = window.innerHeight;
      const scrollTop = document.documentElement.scrollTop;
      const hasReachedBottom = offsetHeight - (innerHeight + scrollTop) <= 10;
      setReachedBottom(hasReachedBottom);
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [reachedBottom]);

  return reachedBottom;
};
