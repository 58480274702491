import { useState } from "react";

import { LoaderFunctionArgs, type MetaFunction, redirect } from "@remix-run/node";
import { useFetcher } from "@remix-run/react";
import { signInAnonymously } from "firebase/auth";
import { useTranslation } from "react-i18next";

import { AppBar, BottomBar, Button, Container } from "~/components";
import { Loader } from "~/components/Loader";
import { APP_NAME } from "~/config";
import { auth as clientAuth } from "~/firebase.client";
import { usePageBottom } from "~/hooks";
import { VascoLogo } from "~/icons";
import { getSession } from "~/sessions";
import utils, { LicenseContentKeys, contentLicenses } from "~/utils";

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const cookie = request.headers.get("Cookie");
  const session = await getSession(cookie);

  if (session.has("uid")) {
    throw redirect("/join-lecture");
  }

  return null;
};

export const meta: MetaFunction<typeof loader> = () => {
  return [{ title: APP_NAME }];
};

export default function Index() {
  const { t, i18n } = useTranslation();
  const licenseContent = contentLicenses[i18n.language as LicenseContentKeys] || contentLicenses["en"];
  const isBottomReached = usePageBottom();
  const [isLoading, setIsLoading] = useState(false);
  const fetcher = useFetcher();

  const onAcceptTerms = async () => {
    try {
      setIsLoading(true);
      const { user } = await signInAnonymously(clientAuth);
      const jwt = await user.getIdToken(true);
      fetcher.submit({ jwt }, { method: "post", action: "/login" });
    } catch (e: unknown) {
      setIsLoading(false);
      // TODO: error handler
      utils.error.capture(e);
    }
  };

  return (
    <Container>
      <AppBar>
        <VascoLogo />
      </AppBar>
      {isLoading && <Loader>{t("label_action_loading_accepting_license")}</Loader>}
      <div className="px-4 pt-4 pb-6 flex flex-col flex-1">
        <div className="text-xs py-1">
          <p>{licenseContent.appName}</p>
          <p>{licenseContent.version}</p>
          <p>{licenseContent.copyright}</p>
        </div>

        <div className="text-2xl font-bold my-6">{licenseContent.title}</div>

        <section>
          <h2 className="font-bold text-lg mb-6">{licenseContent.sections.preliminary.title}</h2>
          <ol className="list-decimal ml-4">
            {licenseContent.sections.preliminary.items.map((item, index) => (
              <li key={index} className="mb-6">
                {item}
              </li>
            ))}
          </ol>
          {licenseContent.sections.preliminary.paragraphs.map((paragraph, index) => (
            <p key={index} className="mt-6">
              {paragraph}
            </p>
          ))}
        </section>

        <section className="mt-6">
          <h2 className="font-bold text-lg mb-6">{licenseContent.sections.services.title}</h2>
          <ol className="list-decimal ml-4">
            {licenseContent.sections.services.items.map((item, index) => (
              <li key={index} className="mb-6">
                {item}
              </li>
            ))}
          </ol>
          {licenseContent.sections.services.paragraphs.map((paragraph, index) => (
            <p key={index} className="mt-6">
              {paragraph}
            </p>
          ))}
        </section>
      </div>

      <BottomBar>
        <Button onClick={onAcceptTerms} disabled={!isBottomReached}>
          {t("label_noun_agree_and_continue")}
        </Button>
      </BottomBar>
    </Container>
  );
}
